.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}



.img-fluid {
  max-width: 100%;
  height: auto;
  margin-right: 100%;
  }

  .img-fluid200pct {
    max-width: 200%;
    height: auto;
  }

  .img-fluid50pct {
    max-width: 50%;
    height: auto;
  }